import classUtils from "modules/classUtils";

if (document.querySelector("body.homepage")) {

    let navElement = document.querySelector("nav");
    checkScroll();
    
    function checkScroll() {
        /* nav is only light on homepage at top, in any other case always dark */
        if (isDesktop() && isAtTop()) {
            classUtils.toggleClass(navElement, "navbar-dark", "navbar-light");
        } else {
            classUtils.toggleClass(navElement, "navbar-light", "navbar-dark");
        }
    }

    function isAtTop() {
        return (document.documentElement.scrollTop < 10);
    }

    function isDesktop() {
        let width = 0;
        if (window.outerWidth) {
            width = window.outerWidth;
        } else {
            width = document.body.clientWidth;
        }
        return (width > 768);
    }

    window.addEventListener('scroll', function (e) {
        checkScroll();
    });

    window.addEventListener('resize', function (e) {
        checkScroll();
    });
}

exports.hasClass = hasClass;
exports.toggleClass = toggleClass;
exports.switchClass = switchClass;


function hasClass(el, className) {
    let has = false;
    if (el.classList) {
        has = el.classList.contains(className);
    } else {
        let classes = el.className.split(" ");
        has = (classes.indexOf(className) >= 0);
    }
    return has;
}

function toggleClass(el, classFrom, classTo) {
    if (el.classList) {
        if (classFrom !== null && classFrom !== "" && el.classList.contains(classFrom)) {
            el.classList.remove(classFrom);
        }
        if (classTo !== null && classTo !== "" && !el.classList.contains(classTo)) {
            el.classList.add(classTo);
        }
    } else {
        /* For IE9 */
        let classes = el.className.split(" ");
        let posFrom = classes.indexOf(classFrom);
        if (posFrom >= 0) {
            classes.splice(posFrom, 1);
        }
        let posTo = classes.indexOf(classFrom);
        if (posTo <= 0) {
            classes.push(classTo);
        }
        el.className = classes.join(" ");
    }
}

function switchClass(el, class1, class2) {
    let from1To2 = true;
    if (hasClass(el, class1)) {
        toggleClass(el, class1, class2);
    } else {
        toggleClass(el, class2, class1);
        from1To2 = false;
    }
    return from1To2;
}

document.addEventListener("DOMContentLoaded", () => {
    let spinnerButtons = document.querySelectorAll(".btn-spinner");
    if (spinnerButtons.length > 0) {
        spinnerButtons.forEach(function (el) {
            if (el.innerHTML.indexOf("spinner") < 0) {
                el.innerHTML = el.innerHTML + " <span class='spinner spinner-border spinner-custom' role='status' aria-hidden='true' style='display:none;'></span>";
                console.log("el.tagName=" + el.tagName);
                if (el.tagName === "A") {
                    el.addEventListener("click", function (ev) {
                        el.querySelector(".spinner").style.display = "";
                    });
                }
            }
        });
    }
});
